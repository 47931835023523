import useRemoteVisit from '@/hooks/useRemoteVisit';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import './RemoteVisit.scss';
import classNames from '@/helpers/classNames';
import {Controller, useFieldArray, useForm} from 'react-hook-form';
import LoadingQuestionnaireModule from '../LoadingQuestionnaireModule/LoadingQuestionnaireModule';
import useSaveRemoteVisit from '@/hooks/useSaveRemoteVisit';
import Saving from '../Saving/Saving';
import {DatePicker, TimePicker} from '@mui/x-date-pickers';

function RemoteVisit({assessmentId}) {
  const {remoteVisitData, isLoading} = useRemoteVisit({logbookId: assessmentId});
  const {trigger, isMutating, isError: isSaveError} = useSaveRemoteVisit(assessmentId);
  const getRemoteVisitAttempts = (attempts) => {
    return attempts.map((attempt) => {
      return {...attempt, 
        durationHour: attempt.duration && attempt.duration?.split(':')[0],
        durationMinute: attempt.duration && attempt.duration?.split(':')[1]}
    });
  }
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: {errors},
  } = useForm({
    values: {
      occurred: remoteVisitData?.occurred,
      durationHour: remoteVisitData?.duration && remoteVisitData.duration?.split(':')[0],
      durationMinute: remoteVisitData?.duration && remoteVisitData.duration?.split(':')[1],
      observations: remoteVisitData?.observations,
      geolocationConfirmed: remoteVisitData?.geolocationConfirmed,
      geolocationObservations: remoteVisitData?.geolocationObservations,
      totalSecurityQuestions: remoteVisitData?.totalSecurityQuestions,
      securityQuestionsObservations: remoteVisitData?.securityQuestionsObservations,
      securityVerificationRisksDetected: remoteVisitData?.securityVerificationRisksDetected,
      securityVerificationObservations: remoteVisitData?.securityVerificationObservations,
      modules: remoteVisitData?.modules,
      generalObservations: remoteVisitData?.generalObservations,
      remoteVisitAttempts: remoteVisitData?.attempts?.length
        ? getRemoteVisitAttempts(remoteVisitData.attempts)
        : [
            {
              scheduled: null,
              visitDate: null,
              occurred: null,
              duration: null,
              observations: '',
            },
          ],
    },
  });
  const {fields: remoteVisitAttemptsFields, append: appendRemoteVisitAttempt} = useFieldArray({
    control,
    name: 'remoteVisitAttempts',
  });
  const remoteVisitAttempts = watch('remoteVisitAttempts');
  const remoteVisitScheduled = (index) => Boolean(remoteVisitAttempts[index].scheduled);
  const remoteVisitOccurred = (index) => Boolean(remoteVisitAttempts[index].occurred);
  const getRemoteVisitDate = () =>
    Boolean(remoteVisitData?.attempts?.length) &&
    remoteVisitData.attempts[remoteVisitData.attempts.length - 1].visitDate;
  const hasQuestionModules = Boolean(remoteVisitData?.modules?.length);
  const getFormattedDate = (date, isDisplayFormat) =>
    isDisplayFormat
      ? `${dayjs(date).format('DD/MMM/YYYY\xa0 HH : mm')} hrs`
      : dayjs(date).format('YYYY-MM-DD HH:mm:ss');
  const handleModuleSelection = (id, key) => {
    const currentValues = getValues('modules') || [];
    setValue(
      'modules',
      currentValues.map((value) => {
        if (value.id === id) {
          return {...value, [key]: !value[key]};
        }
        return value;
      })
    );
  };
  const currentModules = watch('modules');
  const securityVerifiedModules =
    (hasQuestionModules &&
      currentModules?.filter((questionModule) => questionModule.securityVerificationSelected)) ||
    [];
  const dateFormat = 'YYYY-MM-DD HH:mm:ss';

  const onSubmit = (data) => {
    trigger(
      JSON.stringify({
        visitDate: getRemoteVisitDate() ? getFormattedDate(getRemoteVisitDate()) : null,
        occurred: typeof data.occurred === 'undefined' ? null : data.occurred,
        duration: `${data.durationHour}:${data.durationMinute}`,
        observations: data.observations || '',
        geolocationConfirmed:
          typeof data.geolocationConfirmed === 'undefined' ? null : data.geolocationConfirmed,
        geolocationObservations: data.geolocationObservations || '',
        totalSecurityQuestions: data.totalSecurityQuestions
          ? Number(data.totalSecurityQuestions)
          : null,
        securityQuestionsObservations: data.securityQuestionsObservations || '',
        securityVerificationRisksDetected:
          typeof data.securityVerificationRisksDetected === 'undefined'
            ? null
            : data.securityVerificationRisksDetected,
        securityVerificationObservations: data.securityVerificationObservations || '',
        modules: data.modules || remoteVisitData.modules,
        generalObservations: data.generalObservations,
        attempts: data.remoteVisitAttempts.map((attempt) => ({
          scheduled: typeof attempt.scheduled === 'undefined' ? null : attempt.scheduled,
          visitDate:
            attempt.visitDate && attempt.visitDateHour
              ? dayjs(
                  dayjs(attempt.visitDate).format('YYYY-MM-DD') +
                    dayjs(attempt.visitDateHour).format('HH:mm:ss')
                ).format(dateFormat)
              : null,
          occurred: typeof attempt.occurred === 'undefined' ? null : attempt.occurred,
          duration: 
            attempt.durationHour && attempt.durationMinute
              ? `${attempt.durationHour}:${attempt.durationMinute}` : null,
          observations: attempt.observations,
        })),
      })
    );
  };

  if (isLoading) {
    return (
      <div className="container remote-visit dashboard">
        <LoadingQuestionnaireModule className="mt-3" />
      </div>
    );
  }

  return (
    <div className="container remote-visit dashboard">
      <div className="name mb-5">Visita Remota</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-12 outlined-background mb-4">
            <h5 className="fw-semibold mb-3">Visita Remota</h5>
            <div className="remote-visit">
              {remoteVisitAttemptsFields.map(
                (remoteVisitAttemptFields, remoteVisitAttemptIndex) => (
                  <div className="row remote-visit-attempt" key={remoteVisitAttemptFields.id}>
                    <div className="col-5">
                      <h6 className="attempt-title fw-semibold">
                        Intento {remoteVisitAttemptIndex + 1}
                      </h6>
                      <div className="attempt-scheduled">
                        <div className="first-contact-reached">¿Ya agendaste la visita remota?</div>
                        <div className="d-flex">
                          <Controller
                            control={control}
                            name={`remoteVisitAttempts.${remoteVisitAttemptIndex}.scheduled`}
                            rules={{validate: (value) => typeof value === 'boolean'}}
                            render={({field}) => (
                              <>
                                <div className="form-check form-check-inline">
                                  <input
                                    {...field}
                                    checked={field.value === true}
                                    onChange={() => field.onChange(true)}
                                    {...classNames(
                                      'form-check-input',
                                      errors?.remoteVisitAttempts?.[remoteVisitAttemptIndex]
                                        ?.scheduled && 'is-invalid'
                                    )}
                                    type="radio"
                                    value="true"
                                    id={`remoteVisitAttempts.${remoteVisitAttemptIndex}.scheduled-true`}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`remoteVisitAttempts.${remoteVisitAttemptIndex}.scheduled-true`}
                                  >
                                    Si
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    {...field}
                                    checked={field.value === false}
                                    onChange={() => {
                                      field.onChange(false);
                                      setValue(
                                        `remoteVisitAttempts.${remoteVisitAttemptIndex}.occurred`,
                                        null
                                      );
                                    }}
                                    {...classNames(
                                      'form-check-input',
                                      errors?.remoteVisitAttempts?.[remoteVisitAttemptIndex]
                                        ?.scheduled && 'is-invalid'
                                    )}
                                    type="radio"
                                    value="false"
                                    id={`remoteVisitAttempts.${remoteVisitAttemptIndex}.scheduled-false`}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`remoteVisitAttempts.${remoteVisitAttemptIndex}.scheduled-false`}
                                  >
                                    No
                                  </label>
                                </div>
                              </>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-2" />
                    <div className="col-5">
                      <div className="attempt-visit-date">
                        <div className="attempt-date-title">Horario en que se agendó la cita</div>
                        <div className="row">
                          <div className="col-5">
                            <p className="mb-0">Fecha</p>
                            <Controller
                              control={control}
                              name={`remoteVisitAttempts.${remoteVisitAttemptIndex}.visitDate`}
                              disabled={!remoteVisitScheduled(remoteVisitAttemptIndex)}
                              defaultValue={null}
                              render={({field}) => (
                                <DatePicker
                                  id={`remoteVisitAttempts.${remoteVisitAttemptIndex}.visitDate-value`}
                                  className="date-field w-100"
                                  value={field.value && dayjs(field.value)}
                                  disabled={!remoteVisitScheduled(remoteVisitAttemptIndex)}
                                  inputRef={field.ref}
                                  onChange={(value) =>
                                    setValue(
                                      `remoteVisitAttempts.${remoteVisitAttemptIndex}.visitDate`,
                                      dayjs(value)
                                    )
                                  }
                                  slotProps={{
                                    textField: {
                                      id: `remoteVisitAttempts.${remoteVisitAttemptIndex}.visitDate-value`,
                                    },
                                  }}
                                />
                              )}
                            />
                          </div>
                          <div className="col-5">
                            <p className="mb-0">Hora</p>
                            <Controller
                              control={control}
                              name={`remoteVisitAttempts.${remoteVisitAttemptIndex}.visitDateHour`}
                              disabled={!remoteVisitScheduled(remoteVisitAttemptIndex)}
                              defaultValue={
                                remoteVisitAttemptFields.visitDate
                                  ? dayjs(remoteVisitAttemptFields.visitDate)
                                  : null
                              }
                              render={({field}) => (
                                <TimePicker
                                  id={`remoteVisitAttempts.${remoteVisitAttemptIndex}.visitDateHour-value`}
                                  className="date-field w-100"
                                  value={field.value && dayjs(field.value)}
                                  disabled={!remoteVisitScheduled(remoteVisitAttemptIndex)}
                                  inputRef={field.ref}
                                  onChange={(value) =>
                                    setValue(
                                      `remoteVisitAttempts.${remoteVisitAttemptIndex}.visitDateHour`,
                                      dayjs(value)
                                    )
                                  }
                                  slotProps={{
                                    textField: {
                                      id: `remoteVisitAttempts.${remoteVisitAttemptIndex}.visitDateHour-value`,
                                    },
                                  }}
                                  ampm
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-5">
                      <div className="attempt-occurred">
                        <div className="attempt-response-title">
                          ¿Se llevó a cabo la visita remota?
                        </div>
                        <div className="d-flex">
                          <Controller
                            control={control}
                            name={`remoteVisitAttempts.${remoteVisitAttemptIndex}.occurred`}
                            disabled={!remoteVisitScheduled(remoteVisitAttemptIndex)}
                            render={({field}) => (
                              <div>
                                <div className="form-check form-check-inline">
                                  <input
                                    {...field}
                                    checked={field.value === true}
                                    onChange={() => field.onChange(true)}
                                    className="form-check-input"
                                    type="radio"
                                    value="true"
                                    id={`remoteVisitAttempts.${remoteVisitAttemptIndex}.occurred-true`}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`remoteVisitAttempts.${remoteVisitAttemptIndex}.occurred-true`}
                                  >
                                    Si
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    {...field}
                                    checked={field.value === false}
                                    onChange={() => field.onChange(false)}
                                    className="form-check-input"
                                    type="radio"
                                    value="false"
                                    id={`remoteVisitAttempts.${remoteVisitAttemptIndex}.occurred-false`}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`remoteVisitAttempts.${remoteVisitAttemptIndex}.occurred-false`}
                                  >
                                    No
                                  </label>
                                </div>
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-2" />
                    <div className="col-5">
                      <div className="duration">
                        <div className="mb-1">Duración</div>
                        <div className="d-flex align-items-center">
                          <input
                            className="form-control me-2 mt-0 mb-0"
                            type="number"
                            disabled={
                              !remoteVisitScheduled(remoteVisitAttemptIndex) ||
                              !remoteVisitOccurred(remoteVisitAttemptsFields.length - 1)
                            }
                            min="0"
                            max="24"
                            {...register(
                              `remoteVisitAttempts.${remoteVisitAttemptIndex}.durationHour`
                            )}
                          />
                          hr :
                          <input
                            className="form-control ms-2 me-2 mt-0 mb-0"
                            type="number"
                            disabled={
                              !remoteVisitScheduled(remoteVisitAttemptIndex) ||
                              !remoteVisitOccurred(remoteVisitAttemptsFields.length - 1)
                            }
                            min="0"
                            max="59"
                            {...register(
                              `remoteVisitAttempts.${remoteVisitAttemptIndex}.durationMinute`
                            )}
                          />
                          min
                        </div>
                      </div>
                    </div>
                    <div className="col-5">
                      <div className="attempt-observations">
                        <label
                          htmlFor={`remoteVisitAttempts.${remoteVisitAttemptIndex}.observations`}
                        >
                          Observaciones
                        </label>
                        <textarea
                          {...register(
                            `remoteVisitAttempts.${remoteVisitAttemptIndex}.observations`
                          )}
                          id={`remoteVisitAttempts.${remoteVisitAttemptIndex}.observations`}
                          className="form-control"
                          rows="1"
                        />
                      </div>
                    </div>
                    <hr className="mt-4" />
                  </div>
                )
              )}
              <div className="attempt-add d-flex justify-content-center mt-3 mb-3">
                <button
                  className="btn btn-warning rounded-pill add-element-btn"
                  type="button"
                  disabled={
                    !remoteVisitScheduled(remoteVisitAttemptsFields.length - 1) ||
                    remoteVisitOccurred(remoteVisitAttemptsFields.length - 1)
                  }
                  onClick={() =>
                    appendRemoteVisitAttempt({
                      scheduled: null,
                      visitDate: null,
                      occurred: null,
                      observations: '',
                    })
                  }
                >
                  <i className="bi bi-plus-lg" /> Agregar Intento
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 outlined-background mb-4">
            <h5 className="fw-semibold mb-3">Geolocalización</h5>
            <div className="row geolocation">
              <div className="col-7">
                <div>Confirmada</div>
                <Controller
                  control={control}
                  name="geolocationConfirmed"
                  render={({field}) => (
                    <div className="d-flex">
                      <div className="form-check form-check-inline pe-4 me-5">
                        <input
                          id="geolocationConfirmed-yes"
                          className="form-check-input"
                          type="radio"
                          value="true"
                          {...field}
                          checked={field.value === true}
                          onChange={() => field.onChange(true)}
                        />
                        <label htmlFor="geolocationConfirmed-yes" className="form-check-label">
                          Si
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          id="geolocationConfirmed-no"
                          className="form-check-input"
                          type="radio"
                          value="false"
                          {...field}
                          checked={field.value === false}
                          onChange={() => field.onChange(false)}
                        />
                        <label htmlFor="geolocationConfirmed-no" className="form-check-label">
                          No
                        </label>
                      </div>
                    </div>
                  )}
                />
              </div>
              <div className="col-5">
                <div className="mb-2">Observaciones</div>
                <textarea
                  className="form-control"
                  rows="3"
                  {...register('geolocationObservations')}
                />
              </div>
            </div>
          </div>
          <div className="col-12 outlined-background mb-4">
            <h5 className="fw-semibold mb-3">Preguntas de Seguridad</h5>
            <div className="row security-questions">
              <div className="col-7">
                <div className="mb-1">Preguntas</div>
                <select {...classNames('form-select')} {...register('totalSecurityQuestions')}>
                  <option value="" disabled>
                    Seleccione una opción
                  </option>
                  <option value="1">1 Pregunta</option>
                  <option value="2">2 Preguntas</option>
                  <option value="3">3 Preguntas</option>
                  <option value="4">4 Preguntas</option>
                  <option value="5">5 Preguntas</option>
                </select>
              </div>
              <div className="col-5">
                <div className="mb-2">Observaciones</div>
                <textarea
                  className="form-control"
                  rows="3"
                  {...register('securityQuestionsObservations')}
                />
              </div>
            </div>
          </div>
          <div className="col-12 outlined-background mb-4">
            <h5 className="fw-semibold mb-3">Verificación de preguntas aleatorias</h5>
            <div className="row security-questions-verification">
              <div className="col-7">
                <div className="mb-2">¿De qué módulos realizaste las preguntas?</div>
                <Controller
                  control={control}
                  name="modules"
                  render={({field}) => (
                    <div className="checkbox-group row g-2 pt-2">
                      {hasQuestionModules &&
                        remoteVisitData.modules.map((questionModule, questionModuleIndex) => (
                          <div
                            className="d-flex justify-content-start col-6"
                            key={`questionModule-${questionModuleIndex}-option`}
                          >
                            <input
                              id={`questionModule-${questionModuleIndex}-radio`}
                              type="radio"
                              className="form-check-input"
                              onChange={() => {}}
                              checked={
                                field.value?.[questionModuleIndex]?.securityVerificationSelected ||
                                false
                              }
                              onClick={() =>
                                handleModuleSelection(
                                  questionModule.id,
                                  'securityVerificationSelected'
                                )
                              }
                            />
                            <label
                              htmlFor={`questionModule-${questionModuleIndex}-radio`}
                              className="form-check-label"
                            >
                              {questionModule.name}
                            </label>
                          </div>
                        ))}
                    </div>
                  )}
                />
                <div className="mb-2 mt-4 pt-1">¿Detectaste elementos de riesgo?</div>
                <Controller
                  control={control}
                  name="securityVerificationRisksDetected"
                  render={({field}) => (
                    <div className="d-flex">
                      <div className="form-check form-check-inline">
                        <input
                          id="securityVerificationRisksDetected-yes"
                          className="form-check-input"
                          type="radio"
                          value="true"
                          {...field}
                          checked={field.value === true}
                          onChange={() => field.onChange(true)}
                        />
                        <label
                          htmlFor="securityVerificationRisksDetected-yes"
                          className="form-check-label"
                        >
                          Si
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          id="securityVerificationRisksDetected-no"
                          className="form-check-input"
                          type="radio"
                          value="false"
                          {...field}
                          checked={field.value === false}
                          onChange={() => field.onChange(false)}
                        />
                        <label
                          htmlFor="securityVerificationRisksDetected-no"
                          className="form-check-label"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  )}
                />
                <div className="mb-2 mt-3">¿En cuáles?</div>
                <Controller
                  control={control}
                  name="modules"
                  render={({field}) => (
                    <div className="checkbox-group row g-2 pt-2">
                      {securityVerifiedModules?.map(
                        (questionModule, questionModuleIndex) =>
                          questionModule.securityVerificationSelected && (
                            <div
                              className="d-flex justify-content-start col-6"
                              key={`questionModule-risksDetected-${questionModuleIndex}-option`}
                            >
                              <input
                                id={`questionModule-risksDetected-${questionModuleIndex}-radio`}
                                type="radio"
                                className="form-check-input"
                                onChange={() => {}}
                                checked={
                                  field.value?.find(
                                    (fieldModules) => fieldModules.id === questionModule.id
                                  )?.risksDetected || false
                                }
                                onClick={() =>
                                  handleModuleSelection(questionModule.id, 'risksDetected')
                                }
                              />
                              <label
                                htmlFor={`questionModule-risksDetected-${questionModuleIndex}-radio`}
                                className="form-check-label"
                              >
                                {questionModule.name}
                              </label>
                            </div>
                          )
                      )}
                    </div>
                  )}
                />
              </div>
              <div className="col-5">
                <div className="mb-2">Observaciones</div>
                <textarea
                  className="form-control"
                  rows="3"
                  {...register('securityVerificationObservations')}
                />
              </div>
            </div>
          </div>
          <div className="col-12 outlined-background mb-4">
            <h5 className="fw-semibold mb-3">Observaciones de Visita Remota</h5>
            <div className="row general-observations">
              <div className="col-12">
                <textarea className="form-control" rows="3" {...register('generalObservations')} />
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12">
            <div className="submit-button d-flex justify-content-center mt-3 save-controls">
              <button className="btn btn-warning save" type="submit">
                Guardar
              </button>
            </div>
          </div>
        </div>
      </form>
      <Saving
        isMutating={isMutating}
        isSaveError={isSaveError}
        savingMessage="Guardando visita remota, por favor no cierre el navegador o la pestaña..."
        errorMessage="Error al guardar la visita remota, verifica los campos e intenta de nuevo en unos segundos..."
        successMessage="¡Visita remota guardada con éxito!"
      />
    </div>
  );
}

RemoteVisit.propTypes = {
  assessmentId: PropTypes.string,
};

export default RemoteVisit;
